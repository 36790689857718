var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"custom-compact-form",staticStyle:{"width":"1200px"},attrs:{"form":_vm.form,"label-col":{ span: 2 },"wrapper-col":{ span: 20 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"协议名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入协议名称' },
            { max: 20, message: '最多20个字符' } ]
        }]),expression:"['name', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入协议名称' },\n            { max: 20, message: '最多20个字符' },\n          ]\n        }]"}],staticStyle:{"width":"200px"},attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"版本号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['version', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入版本号' },
            { max: 20, message: '最多20个字符' } ]
        }]),expression:"['version', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入版本号' },\n            { max: 20, message: '最多20个字符' },\n          ]\n        }]"}],staticStyle:{"width":"200px"},attrs:{"autocomplete":"off"}})],1),_c('a-form-item',[_c('wang-editor-frequently-asked-question',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
          rules: [
            { max: 2000, message: '最多2000个字符' } ]
        }]),expression:"['content', {\n          rules: [\n            { max: 2000, message: '最多2000个字符' },\n          ]\n        }]"}]})],1),_c('a-form-item',{staticClass:"set-button-center"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.submitting}},[_vm._v(" 保存 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }